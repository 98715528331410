import { useEffect, useState } from "react";
import cl from "./ProfileSettings.module.css"
import { useNavigate } from "react-router-dom";
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import InputUniversal from "../../components/universalComponents/inputUniversal/InputUniversal";
import { observer } from "mobx-react-lite";
import firstTwoPageStore from "../../store/FirstTwoPageStore";
import ButtonUniversal from "../../components/universalComponents/buttonUniversal/ButtonUniversal";
import getStore from "../../store/GetStore";
import axios from "axios";
import ModalUniversal from '../../components/universalComponents/modalUniversal/ModalUniversal';
import templateStore from "../../store/TemplatesStore";
import _ from 'lodash';


const ProfileSettings = observer(() => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    useEffect(() => {
        const initData = tg.initData;

        const parseInitData = (initData) => {
            return Object.fromEntries(new URLSearchParams(initData));
        };

        const parsedData = parseInitData(initData);

        localStorage.setItem('botUsername', JSON.parse(parsedData.user).id);

        if (!tg.isClosingConfirmationEnabled) tg.enableClosingConfirmation();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // localStorage.setItem('botUsername', 'wehfbwkeffhewlkfketetris_bot')

    const botUsername = localStorage.getItem('botUsername');

    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        const getSelfEmployedRequest = async () => {
            if (firstTwoPageStore.hasFetchedSelfEmployedData) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}`);
                firstTwoPageStore.selfEmployedName = response.data.name === null ? '' : response.data.name;
                firstTwoPageStore.selfEmployedAddress = response.data.address === null ? '' : response.data.address;
                firstTwoPageStore.selfEmployedContact = response.data.contact === null ? '' : response.data.contact;

                sessionStorage.setItem('initialSelfEmployedName', response.data.name === null ? '' : response.data.name)
                sessionStorage.setItem('initialSelfEmployedAddress', response.data.address === null ? '' : response.data.address)
                sessionStorage.setItem('initialSelfEmployedContact', response.data.contact === null ? '' : response.data.contact)

                firstTwoPageStore.hasFetchedSelfEmployedData = true;

                console.log("Загружены данные о настройках профиля", response);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    firstTwoPageStore.isBotRegistration = true;
                    navigate("/instructions");
                } else {
                    const errorCode = error.response?.status || 500;
                    const errorMessage = error.response?.statusText || 'Internal Server Error';
                    navigate('/errorPage', { state: { errorCode, errorMessage } });
                }
            }
        }

        getSelfEmployedRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickBotSelection = () => {
        tg.BackButton.show();
        navigate('/botSettings');
    }

    const handleClickServiceSeletion = () => {
        navigate("/servicesPage");
    }

    const handleClickShedule = () => {
        const getTimeData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`);
                getStore.timeData = _.cloneDeep(response.data.workingDays.days);
                templateStore.templatesData = response.data.templates;
                getStore.isTimeDataLoaded = true;
                getStore.version = response.data.workingDays.version;
                getStore.initialTimeData = _.cloneDeep(response.data.workingDays.days);

                console.log("Загружены данны о расписании");
            } catch (error) {
                console.error('Ошибка загрузки данных о расписании', error);
                const errorCode = error.response?.status || 500;
                const errorMessage = error.response?.statusText || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }
        getTimeData();

        navigate("/sсhedule");
    }

    useEffect(() => {
        if (firstTwoPageStore.selfEmployedAddress.trim() !== ""
            && firstTwoPageStore.selfEmployedName.trim() !== ""
            && (firstTwoPageStore.selfEmployedAddress.trim() !== sessionStorage.getItem('initialSelfEmployedAddress')
                || firstTwoPageStore.selfEmployedName.trim() !== sessionStorage.getItem('initialSelfEmployedName')
                || firstTwoPageStore.selfEmployedContact.trim() !== sessionStorage.getItem('initialSelfEmployedContact'))) {
            if (!tg.MainButton.isVisible) tg.MainButton.show();
        } else {
            if (tg.MainButton.isVisible) tg.MainButton.hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstTwoPageStore.selfEmployedAddress, firstTwoPageStore.selfEmployedName, firstTwoPageStore.selfEmployedContact])

    useEffect(() => {
        // const handleBackButtonOnClick = () => {
        //     navigate("/instructions");
        // }

        tg.BackButton.hide();
        tg.MainButton.enable();

        const handleMainButtonOnClick = async () => {
            tg.MainButton.disable();
            const patchData = {
                name: firstTwoPageStore.selfEmployedName,
                address: firstTwoPageStore.selfEmployedAddress,
                contact: firstTwoPageStore.selfEmployedContact
            }

            try {
                const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}`, patchData);
                console.log("ProficeSettings обновлены", response.data);
                // tg.sendData('SETUP_HAS_BEEN_COMPLETED');
                tg.close();
            } catch (error) {
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }
        };

        // tg.BackButton.onClick(handleBackButtonOnClick);

        tg.MainButton.onClick(handleMainButtonOnClick);

        tg.MainButton.setText("Готово");

        if (tg.MainButton.color !== "#34C759") {
            tg.MainButton.color = "#34C759"
            tg.MainButton.textColor = "#FFFFFF"
        }

        return () => {

            // tg.BackButton.offClick(handleBackButtonOnClick);
            tg.MainButton.offClick(handleMainButtonOnClick);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <HeaderUniversal>Настройки профиля</HeaderUniversal>
            <div>
                <ButtonUniversal
                    title={'Telegram-бот'}
                    body={'Тут живет ваш бот'}
                    onClick={handleClickBotSelection}
                />
            </div>

            <div className={cl.selfEmployedNameContainer}>
                <InputUniversal
                    title={"Как вас зовут?"}
                    value={firstTwoPageStore.selfEmployedName}
                    placeholder={"Имя фамилия"}
                    // onChange={handleChangeSelfEmployedName}
                    setValue={firstTwoPageStore.setSelfEmployedName}
                />
            </div>

            <div className={cl.serviceSelectionContainer}>
                <ButtonUniversal
                    title={"Какие услуги вы оказываете?"}
                    body={"Выбрать услуги"}
                    onClick={handleClickServiceSeletion}
                />
            </div>

            <div className={cl.selfEmployedAddressContainer}>
                <InputUniversal
                    title={"Где вы оказываете услуги?"}
                    value={firstTwoPageStore.selfEmployedAddress}
                    placeholder={"Адрес"}
                    setValue={firstTwoPageStore.setSelfEmployedAddress}
                    footnote={"Адрес будет отображаться клиентам"}
                />
            </div>

            <div className={cl.scheduleContainer}>
                <ButtonUniversal
                    title={"Режим работы"}
                    body={"Настроить режим работы"}
                    onClick={handleClickShedule}
                />
            </div>

            <div>
                <InputUniversal
                    title={'Как можно с вами связаться?'}
                    value={firstTwoPageStore.selfEmployedContact}
                    placeholder={'Телефон, телеграм или другое'}
                    setValue={firstTwoPageStore.setSelfEmployedContact}
                    footnote={'По желанию. Способ связи для клиентов'}
                />
            </div>

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось сохранить настройки профиля. Попробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }

            {firstTwoPageStore.showСonflictScheduleModal &&
                <ModalUniversal
                    text={'К вам записался новый клиент, для некоторых дней не удалось изменить расписание'}
                    setIsVisible={firstTwoPageStore.setShowСonflictScheduleModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default ProfileSettings