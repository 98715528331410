import { useEffect, useState } from "react"
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal"
import cl from "./Sсhedule.module.css"
import { useNavigate } from "react-router-dom"
import { HeadlineBody } from "../../textStyles/TextStyleComponents"
import ListOfDaysForShedule from "../../components/sсhedule/listOfDaysForSсhedule/ListOfDaysForSсhedule"
import getStore from "../../store/GetStore"
import { observer } from "mobx-react-lite"
import ModalUniversal from "../../components/universalComponents/modalUniversal/ModalUniversal"
import _ from 'lodash';
import axios from "axios"
import templateStore from "../../store/TemplatesStore"
import firstTwoPageStore from "../../store/FirstTwoPageStore"

const Sсhedule = observer(() => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    const [showBackButtonModal, setShowBackButtonModal] = useState(false);
    const botUsername = localStorage.getItem('botUsername');
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const patchRequest = async (patchData) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`, patchData);
            console.log("Расписание сохранено удален", response.data);

        } catch (error) {
            setShowErrorModal(true);
            tg.MainButton.enable();

            if (error.response) {
                if (error.response.status === 409) {
                    // Обрабатываем конфликт
                    console.error("Конфликт при сохранении расписания", error.response.data.description);
                    firstTwoPageStore.setShowСonflictScheduleModal(true);
                } else {
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                }
            } else if (error.request) {
                console.error("Запрос был сделан, но ответа не было получено", error.request);
            } else {
                console.error("Ошибка:", error.message);
            }
        }
    }

    useEffect(() => {
        const getTemplatesData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`);
                templateStore.templatesData = (response.data.templates);
                console.log("Загружены данны о шаблонах", response);
            } catch (error) {
                console.error('Ошибка загрузки данных о шаблонах', error);
                const errorCode = error.response?.status || 500;
                const errorMessage = error.response?.statusText || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }

        getTemplatesData();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const confirmBackButtonOnClick = () => {
        // getStore.setCurrentTimeData(_.cloneDeep(getStore.timeData));
        navigate("/*");
    }

    useEffect(() => {
        if (!tg.BackButton.isVisible) tg.BackButton.show();
        if (!tg.MainButton.isVisible) tg.MainButton.show();

        const handleBackButtonOnClick = () => {
            if (!_.isEqual(getStore.timeData, getStore.initialTimeData)) {
                setShowBackButtonModal(true);
            } else {
                navigate('*');
            }
        }

        const handleMainButtonOnClick = () => {
            tg.MainButton.disable();
            // getStore.setTimeData(_.cloneDeep(getStore.currentTimeData));
            const patchData = {
                workingDays: {
                    version: getStore.version,
                    days: getStore.timeData
                }
            }
            patchRequest(patchData);
            navigate("/*");
        }

        tg.BackButton.onClick(handleBackButtonOnClick)

        tg.MainButton.onClick(handleMainButtonOnClick)

        tg.MainButton.setText("Сохранить");

        if (tg.MainButton.color !== tg.themeParams.button_color) {
            tg.MainButton.color = tg.themeParams.button_color
            tg.MainButton.textColor = tg.themeParams.button_text_color
        }

        return () => {
            tg.BackButton.offClick(handleBackButtonOnClick);
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getStore.timeData]);

    const handleClickOnTemplates = () => {
        navigate("/scheduleTemplates")
    }

    if (!getStore.isTimeDataLoaded) {
        return null;
    }

    return (
        <div>
            <HeaderUniversal className={cl.header}>Режим работы</HeaderUniversal>

            <div className={cl.btnContainer}>
                <button onClick={handleClickOnTemplates} className={cl.btn}>
                    <HeadlineBody>Шаблоны</HeadlineBody>
                </button>
            </div>

            {/* <div className={cl.jsonOutput}>
                <h2>Time Data JSON:</h2>
                <pre>{JSON.stringify(getStore.timeData, null, 2)}</pre>
            </div> */}

            <ListOfDaysForShedule />

            {showBackButtonModal &&
                <ModalUniversal
                    text={'Изменения в расписании не будут применены. Вы уверены?'}
                    setIsVisible={setShowBackButtonModal}
                    onConfirm={confirmBackButtonOnClick}
                    confirmText={'Уйти'}
                    cancelText={'Остаться'}
                />
            }

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось сохранить изменения в расписании. Попробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default Sсhedule