import React from 'react';
import cl from './PhotoUploadButton.module.css';
import { ReactComponent as UploadPhotoIcon } from "../../../../icons/UploadPhoto.svg";
import ModalUniversal from '../../../universalComponents/modalUniversal/ModalUniversal'
import { useState } from 'react';

const PhotoUploadButton = ({ body, setServicePhotos, isAddingNewService }) => {
    const fileInputRef = React.createRef();

    const [showModal, setShowModal] = useState(false);

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                // Устанавливаем новый размер для изображения
                const maxSize = 1000; // Максимальная ширина или высота

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // Сжимаем изображение до 500 кб
                let quality = 0.9; // Стартовое качество
                let base64 = canvas.toDataURL('image/jpeg', quality);

                while (base64.length > 500 * 1024 && quality > 0.1) {
                    quality -= 0.1;
                    base64 = canvas.toDataURL('image/jpeg', quality);
                }

                callback(base64);
            };

            img.src = event.target.result;
        };

        reader.readAsDataURL(file);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Проверка расширения файла
            const allowedExtensions = ['jpeg', 'jpg', 'png', 'heic'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                setShowModal(true);
                return;
            }

            compressImage(file, (base64String) => {
                const cleanBase64 = base64String.replace('data:', '').replace(/^.+,/, '');

                if (isAddingNewService) {
                    setServicePhotos(prevPhotos => [...prevPhotos, cleanBase64]);
                } else {
                    setServicePhotos(prevPhotos => [
                        ...prevPhotos,
                        { id: null, base64: cleanBase64 }
                    ]);
                }
            });
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileInput}
            />
            <button className={cl.btn} onClick={handleClick}>
                {body}
                <UploadPhotoIcon />
            </button>

            {showModal &&
                <ModalUniversal
                    text={'Загрузите пожалуйста фотку с расширением JPEG, PNG или HEIC'}
                    setIsVisible={setShowModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    );
};

export default PhotoUploadButton;
