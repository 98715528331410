import { makeObservable, observable, action } from 'mobx';


class FirstTwoPage {
    token = ""
    username = ""
    selfEmployedName = ""
    selfEmployedAddress = ""
    selfEmployedContact = ""

    hasFetchedSelfEmployedData = false;

    showСonflictScheduleModal = false;

    constructor() {
        makeObservable(this, {
            token: observable,
            username: observable,
            selfEmployedName: observable,
            selfEmployedAddress: observable,
            selfEmployedContact: observable,
            showСonflictScheduleModal: observable,
            setToken: action,
            setUsername: action,
            setSelfEmployedName: action,
            setSelfEmployedAddress: action,
            setSelfEmployedContact: action,
            setShowСonflictScheduleModal: action
        });
    }

    setToken = (value) => {
        this.token = value;
    }

    setUsername = (value) => {
        this.username = value;
    }

    setSelfEmployedName = (value) => {
        this.selfEmployedName = value;
    }

    setSelfEmployedAddress = (value) => {
        this.selfEmployedAddress = value;
    }

    setSelfEmployedContact = (value) => {
        this.selfEmployedContact = value;
    }

    setShowСonflictScheduleModal = (value) => {
        this.showСonflictScheduleModal = value;
    }
}

const firstTwoPageStore = new FirstTwoPage();

export default firstTwoPageStore;